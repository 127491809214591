import {
  iconSubmit,
  iconRecall,
  iconCut,
  iconCopy
} from "@/design/icon/iconConst";

/**
 * agenda Item Operation types
 * @type {Readonly<{defer: string, submit: string, refer: string, recall: string}>}
 */
const agendaItemOperation = Object.freeze({
  submit: "AgendaItemSubmit",
  recall: "AgendaItemRecall",
  defer: "AgendaItemDefer",
  refer: "AgendaItemRefer"
});

/**
 * agenda Item Operations
 * @type {({name: string, icon: string, description: string, label: string})[]}
 */
const agendaItemOperations = Object.freeze([
  {
    name: agendaItemOperation.submit,
    label: "Submit",
    description:
      "Submit Agenda Item, so that Agenda Item can be used by other departments",
    icon: iconSubmit
  },
  {
    name: agendaItemOperation.recall,
    label: "Recall",
    description:
      "Recall Submitted Agenda Item, so that Agenda Item can not be used by other departments",
    icon: iconRecall
  },
  {
    name: agendaItemOperation.defer,
    label: "Defer",
    description: "Defer (Move) Agenda Item",
    icon: iconCut
  },
  {
    name: agendaItemOperation.refer,
    label: "Refer",
    description: "Refer (Copy) Agenda Item",
    icon: iconCopy
  }
]);

/**
 * find Agenda Item Operation
 * @param {String|string} name name of Agenda Item Operation
 * @return {{name: string, icon: string, description: string, label: string}}
 */
const findAgendaItemOperation = name =>
  agendaItemOperations?.find(el => el.name === name);

/**
 * Agenda Item To Item Refer DeferOptions
 * @type {({disabled: boolean, text: string, value: number}|{disabled: boolean, text: string, value: number}|{disabled: boolean, text: string, value: number}|{disabled: boolean, text: string, value: number})[]}
 */
const agendaItemToItemReferDeferOptions = Object.freeze([
  {
    text: "Insert before first item",
    value: 0,
    disabled: false
  },
  {
    text: "Append after last item",
    value: 1,
    disabled: false
  },
  {
    text: "Insert before destination item",
    value: 2,
    disabled: false
  },
  {
    text: "Insert after destination item",
    value: 3,
    disabled: false
  }
]);

/**
 * find Agenda Item to Item Refer Defer Option
 * @param {Number| number} value of Option
 * @return {{text: string, value: number, disabled: boolean}}
 */
const findAgendaItemToItemReferDeferOption = value =>
  agendaItemToItemReferDeferOptions?.find(el => el.value === value);

/**
 * Agenda Item To Section Refer Defer Options
 * @type {({disabled: boolean, text: string, value: number}|{disabled: boolean, text: string, value: number})[]}
 */
const agendaItemToSectionReferDeferOptions = Object.freeze([
  {
    text: "Insert before first item",
    value: 0,
    disabled: false
  },
  {
    text: "Append after last item",
    value: 1,
    disabled: false
  }
]);

/**
 * find Agenda Item to Section Refer Defer Option
 * @param {Number| number} value of Option
 * @return {{text: string, value: number, disabled: boolean}}
 */
const findAgendaItemToSectionReferDeferOption = value =>
  agendaItemToSectionReferDeferOptions?.find(el => el.value === value);

export {
  agendaItemOperation,
  agendaItemOperations,
  findAgendaItemOperation,
  agendaItemToItemReferDeferOptions,
  agendaItemToSectionReferDeferOptions,
  findAgendaItemToItemReferDeferOption,
  findAgendaItemToSectionReferDeferOption
};
